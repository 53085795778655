<template>
    <div class="subject">
        <div>
            <b-tabs pills v-model="tab" class="border-2">
                <b-tab title="مرحلة التعليم الجامعي" active> </b-tab>
                <b-tab title="مرحلة التعليم المدرسي"> </b-tab>
            </b-tabs>
        </div>
        <b-card no-body class="mb-1">
            <b-card-header class="pb-0">
                <strong class="mr-auto">فلترة حسب</strong>
                <b-button
                    type="submit"
                    class="mr-1"
                    variant="primary"
                    @click="
                        getUnitList({ ...filterDto, search: search.search })
                    "
                    >فلترة
                </b-button>
                <b-button variant="secondary" @click="resetFilter"
                    >تهيئة</b-button
                >
            </b-card-header>
            <b-card-body class="pb-50">
                <b-row>
                    <b-col cols="12" md="3">
                        <ek-input-select
                            label="جامعة"
                            placeholder=" اختر جامعة"
                            :options="[
                                { id: 0, name: 'الكل' },
                                ...universityList,
                            ]"
                            v-model="filterDto.universityId"
                            name="select"
                            :clearable="true"
                        />
                    </b-col>
                    <b-col cols="12" md="3">
                        <ek-input-select
                            label="الكلية"
                            placeholder="اختر الكلية "
                            :options="[{ id: 0, name: 'الكل' }, ...facultyList]"
                            name="select"
                            v-model="filterDto.facultyId"
                            :clearable="true"
                        />
                    </b-col>
                    <b-col cols="12" md="3">
                        <ek-input-select
                            label="السنة"
                            placeholder="اختر السنة "
                            :options="[{ id: 0, name: 'الكل' }, ...years]"
                            name="select"
                            v-model="filterDto.yearId"
                            :clearable="true"
                        />
                    </b-col>
                    <b-col cols="12" md="3">
                        <ek-input-select
                            label="الفصل"
                            placeholder="اختر الفصل "
                            :options="[{ id: 0, name: 'الكل' }, ...semesters]"
                            name="select"
                            v-model="filterDto.semesterId"
                            :clearable="true"
                        />
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>
        <b-row class="subjects">
            <b-col lg="3" md="6" v-for="unit in unitList.units" :key="unit.id">
                <b-card id="my-card">
                    <div>
                        <b-badge
                            class="badge"
                            :variant="unit.isFree ? 'danger' : 'primary'"
                            >{{ unit.isFree ? "مدفوع" : "مجاني" }}</b-badge
                        >
                    </div>

                    <template #header class="p-0">
                        <img
                            :src="
                                $store.getters['app/domainHost'] +
                                '/' +
                                unit.imageUrl
                            "
                            alt=""
                            srcset=""
                        />
                    </template>
                    <b-card-body body-class="p-0">
                        <div class="mt-1">
                            <span
                                class="w-100 d-flex justify-content-center align-items-center"
                            >
                                <unicon name="folder" class="pl-1"></unicon>
                                <span class="px-1">
                                    ({{ unit.chaptersCount }})
                                </span>
                            </span>
                            <h3 class="mt-1">{{ unit.name }}</h3>
                            <p>
                                {{ unit.semesterName }}

                                {{ unit.yearName }}
                            </p>
                            <p>
                                {{ unit.facultyName }}
                                -
                                {{ unit.departmentName }}
                            </p>
                            <p>
                                {{ unit.universityName }}
                            </p>
                        </div>
                    </b-card-body>

                    <template #footer>
                        <b-button
                            variant="main-color"
                            class="w-100 p-1"
                            @click="goToDetails(unit.id)"
                            >عرض التفاصيل
                        </b-button>
                    </template>
                </b-card>
            </b-col>
            <div
                class="d-flex justify-content-center align-items-center flex-column w-100"
            >
                <b-pagination
                    v-model="filterDto.pageIndex"
                    pills
                    :total-rows="unitList.count"
                    :per-page="filterDto.pageSize"
                    aria-controls="my-card"
                    @change="paginate"
                >
                </b-pagination>
            </div>
        </b-row>
    </div>
</template>
<script>
import { mapActions, mapState } from "vuex";

export default {
    data() {
        return {
            tab: 0,
            filterDto: {
                semesterId: "",
                yearId: "",
                facultyId: "",
                universityId: "",
                pageSize: 4,
                pageIndex: 1,
                studentType: this.tab,
            },
        };
    },

    computed: {
        ...mapState({
            unitList: (state) => state.unit.unitList,
            universityList: (state) => state.university.universityList,
            facultyList: (state) => state.faculty.facultyList,
            branches: (state) => state.branches.branchList,
            years: (state) => state.settings.yearList,
            semesters: (state) => state.settings.semesterList,
            search: (state) => state.unit.filterDto,
        }),
        // ...mapGetters(["subjectsList"]),
    },
    methods: {
        paginate(e) {
            console.log(e);
            this.getUnitList({
                ...this.filterDto,
                pageIndex: e,
                studentType: this.tab,
            });
        },
        goToDetails(id) {
            this.$router.push({ path: `/admin/unit/${id}` });
        },
        ...mapActions([
            "getUnitList",
            "getFacultyList",
            "getUniversityList",
            "getSettingYear",
            "getSettingSemester",
            "getGetUnRead",
            "getBranchList",
            "getSubjectsNames",
        ]),

        resetFilter() {
            Object.assign(this.filterDto, {
                semesterId: "",
                yearId: "",
                facultyId: "",
                universityId: "",
            });
            this.getUnitList({ ...this.filterDto, studentType: this.tab });
        },
    },
    created() {
        this.getUnitList({ ...this.filterDto, studentType: 0 });
        this.getFacultyList(0);
        this.getUniversityList(0);
        this.getBranchList(0);
        this.getSettingYear();
        this.getSettingSemester();
        this.getGetUnRead();
    },

    watch: {
        tab: function (newTab) {
            if (newTab === 0) {
                this.getUnitList({
                    ...this.filterDto,
                    studentType: 0,
                });
                this.getFacultyList(0);
                this.getUniversityList(0);
                this.getNamesUniversity(0);
                this.getBranchList(0);
                this.getSubjectsNames(0);
            } else {
                this.getUnitList({
                    ...this.filterDto,
                    studentType: 1,
                });
                this.getFacultyList(1);
                this.getUniversityList(1);
                this.getBranchList(1);
                this.getSubjectsNames(1);

                this.getNamesUniversity(1);
            }
        },
    },
};
</script>

<style lang="scss">
@import "/src/@core/scss/core.scss";

.subject {
    #my-card {
        position: relative;
    }
    p {
        margin: 5px;
    }
    .badge {
        position: absolute;
        top: 10px;
        right: 9px;
    }
    img {
        width: 100%;
        height: 200px;
        object-fit: cover;
        border-radius: 10px;
    }

    .unicon.button {
        svg {
            fill: $main-color !important;
        }
    }

    svg {
        fill: #6e6b7b;
    }

    .subjects {
        .card-body {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
        }

        .card-header {
            padding: 0 !important;
        }

        .card-footer {
            padding: 0 !important;
            text-align: center;
            cursor: pointer;

            button {
                color: #fff !important;
                border-top-left-radius: 0 !important;
                border-top-right-radius: 0 !important;
            }
        }
    }
}
</style>
